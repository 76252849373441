import jwt_decode from 'jwt-decode';
import setAuthToken from '../utils/setAuthToken';
import {
    SET_CURRENT_USER,
    GET_ERRORS,
    GET_CURRENCIES_SUCCESS,
    GET_USER_PROFILE_SUCCESS,
    GET_TIME_ZONES_SUCCESS,
    NOTIFICATION_DETAILS_UPDATED,
    GET_SERVICE_PROVIDERS_SUCCESS,
    LOGOUT_USER,
    GET_SETTINGS_DETAILS_SUCCESS,
    GET_CATEGORIES_SUCCESS, GET_LANGUAGES_SUCCESS,
    GET_ACCOUNT_TYPE_SUCCESS,
    GET_ACCOUNT_LIST_SUCCESS,
    GET_GROUP_ACCOUNT_LIST_SUCCESS,
    GROUP_USER_IMAGE_SUCCESS,
    USER_ACCOUNT_DOES_NOT_EXIST
} from './types';
import setLoading from './appActions';
import { history } from '../_helpers';
import * as api from '../services/ApiCall';
import {filterAccountList} from "./accountActions";

//Register User
export const registeruser = (userData, history) => dispatch => {
    dispatch(setLoading(true));
    api.callPost('api/users/signup', userData)
        .then(data => {
            history.push({
                pathname: '/verify-signup',
                state: { email: userData.email }
            });
            dispatch(setLoading(false));
        })
        .catch(err => dispatch(handleErrors(err)));
};



export const preSignin = (data, callback) => dispatch => {
    dispatch(setLoading(true));
    api.callPost('api/users/email/presignin', data)
        .then(data => {
            dispatch(setLoading(false));
            callback(data);
        })
        .catch(err => { 
            if(err.data && err.data.code == 340) {
                dispatch(setLoading(false));
                callback(err.data);
            }else{
                dispatch(handleErrors(err))
            }
        });
}

export const preSigninExistingUser = async(data, callback) => dispatch =>{
    dispatch(setLoading(true));
    api.callPost('api/users/email/presignin', data)
        .then(data => {
            // dispatch(setLoading(false));
            console.log("data :",data)
            callback(data);
        })
        .catch(err => dispatch(handleErrors(err)));
}

export const resendOTP = (emailId, callBack, errorcallback) => dispatch =>  {
    dispatch(setLoading(true));
    api.callPost('api/users/resendOTP', {email: emailId})
        .then(data => {
            dispatch(setLoading(false));
            callBack();
        })
        .catch(err => {dispatch(handleErrors(err)); errorcallback()});
}

export const loginUserOtp = ( userData, callback, errorcallback ) => dispatch => {
    dispatch(setLoading(true));
    api.callPost('auth/otpsignin', userData)
        .then(data => {
            const token = "Bearer " + data.token;
            localStorage.setItem('jwtToken', token);
            setAuthToken(token);
            dispatch(setCurrentUser(jwt_decode(token)));
            dispatch({
                type: NOTIFICATION_DETAILS_UPDATED,
                payload: {}
            });
			dispatch(setLoading(false));
            callback();
        })
        .catch(err => {errorcallback(); dispatch(handleErrors(err)) });
};




//Different passwords
export const differentPasswords = () => dispatch => {
    dispatch({
        type: GET_ERRORS,
        payload: "Password and confirm password do not match, Try again."
    });
};

export const handleErrors = (data = null, type='GET_ERRORS') => dispatch => {
    const dataObj = data && data.data ? data.data : data;
    if(!!dataObj) {
        if (dataObj.code === 401 || dataObj.code === 403) {
            dispatch(logoutUser());
        }
        dispatch({
            type: type,
            payload: {
                message: dataObj.message,
                code: dataObj.code
            }
        });
    } else {
        dispatch({
            type: type,
            payload: {
                message: 'Unknown Error Occured!!',
                code: 404
            }
        });
    }
    dispatch(setLoading(false));
};

export const clearErrors = () => dispatch => {
    dispatch({
        type: GET_ERRORS,
        payload: {}
    });
};



//Login - Get user token
export const loginUser = ( userData ) => dispatch => {
    dispatch(setLoading(true));
    api.callPost('auth/signin', userData)
        .then(data => {
            const token = "Bearer " + data.token;
            localStorage.setItem('jwtToken', token);
            setAuthToken(token);
            dispatch(setCurrentUser(jwt_decode(token)));
            dispatch({
                type: NOTIFICATION_DETAILS_UPDATED,
                payload: {}
            });
			dispatch(setLoading(false));
        })
        .catch(err => dispatch(handleErrors(err)));
};

export const loginWithGoogle = (token) => dispatch => {
    dispatch(setLoading(true));
    const jwtToken = "Bearer " + token;
    setAuthToken(jwtToken);
    localStorage.setItem('jwtToken', jwtToken);
    dispatch(setCurrentUser(jwt_decode(jwtToken)));
    dispatch({
        type: NOTIFICATION_DETAILS_UPDATED,
        payload: {}
    });
    dispatch(setLoading(false));
}

// Set logged in user
export const setCurrentUser = (decoded) => {
    return{
        type:SET_CURRENT_USER,
        payload:decoded
    }
};


export const getUserAccountList = (dispatchFn) => {
    api.callPost('api/userdata/getAllAccounts').then(res => {
        dispatchFn({
            type: GET_ACCOUNT_LIST_SUCCESS,
            payload: filterAccountList(res.results)
        });
    });
};

export const getLoggedInUserAccountList = () => dispatch => {
    api.callPost('api/userdata/getAllAccounts').then(res => {
        dispatch({
            type: GET_ACCOUNT_LIST_SUCCESS,
            payload: filterAccountList(res.results)
        });
    });
};

// Log Out User
export const logoutUser = () => dispatch => {
    // Remove token from local storage
	/*localStorage.removeItem('jwtToken');
	localStorage.removeItem('user');
	localStorage.removeItem('profileDetails');
	localStorage.removeItem('userCategories');*/
	localStorage.clear();
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to {} which will set isAuthenticated to false
	dispatch({
        type: LOGOUT_USER
    });
	history.push('/signin');
};

// Log Out User without navigating him to signin
export const logoutUserWithoutNavigate = () => dispatch => {
    // Remove token from local storage
	/*localStorage.removeItem('jwtToken');
	localStorage.removeItem('user');
	localStorage.removeItem('profileDetails');
	localStorage.removeItem('userCategories');*/
	localStorage.clear();
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to {} which will set isAuthenticated to false
	dispatch({
        type: LOGOUT_USER
    });
};

//Forgot Password
export const forgotPassword = (userData) => dispatch => {
    dispatch(setLoading(true));
    api.callPost('api/users/forgotPassword', userData)
        .then(data => {
            dispatch(setLoading(false));
            dispatch({
                type: GET_ERRORS,
                payload: data
            });
        })
        .catch(err => dispatch(handleErrors(err)));
};

//Reset Password
export const resetPassword = (userData, history) => dispatch => {
    api.callPost('api/users/resetPassword', userData)
        .then(res => {
            history.push({
                pathname: '/reset-success'
            });
        })
        .catch(err => dispatch(handleErrors(err)));
};

//Fetch Profile
export const fetchProfile = (callBack) => dispatch => {
    dispatch(setLoading(true));
    api.callGet('api/users/settings')
        .then(data => {
            const { settings } = data;
            api.callGet(`api/admin/currency/${settings.currency}`)
                .then(_data => {
                    settings.currencyDetails = _data.currency;
                    localStorage.setItem('profileDetails', JSON.stringify(settings));
                    dispatch({
                        type: GET_USER_PROFILE_SUCCESS,
                        payload: settings
                    });
                    if(callBack) {
                        callBack();
                    }
                    dispatch(setLoading(false));
                });
        })
        .catch(err => dispatch(handleErrors(err)));
};

//Account Activation
export const activation = (query) => dispatch => {
    api.callGet(`api/users/activate${query}`)
        .then(res => {
            dispatch({
                type: GET_ERRORS,
                payload: { message: "success" }
            });
        })
        .catch(err => dispatch(handleErrors(err)));
};

//Account Activation
export const resendActivation = (email) => dispatch => {
    api.callGet(`api/users/sendActivation/${email}`)
        .then(data => {
            dispatch({
                type: GET_ERRORS,
                payload: { message: data.message }
            });
        })
        .catch(err => dispatch(handleErrors(err)));
};

//Get Currencies, timezones, languages
export const getSettingsDetails = () => dispatch => {
    let dataObj = {};
    dispatch(setLoading(true));
    api.callGet('api/admin/currencies').then(response => {
        dataObj['currencies'] = response;
        return api.callGet('api/admin/timezones');
    }).then(response => {
        dataObj['timeZones'] = response;
        return api.callGet('api/admin/languages');
    }).then(response => {
        dataObj['languages'] = response;
        dispatch(setLoading(false));
            dispatch({
                type: GET_SETTINGS_DETAILS_SUCCESS,
                payload: dataObj
            })
    })
    .catch(err => dispatch(handleErrors(err)));
};
//Get Currencies
export const getCurrencies = () => dispatch => {
    dispatch(setLoading(true));
    api.callGet('api/admin/currencies')
        .then(data => {
            dispatch(setLoading(false));
            dispatch({
                type: GET_CURRENCIES_SUCCESS,
                payload: data.Currencies
            })
        })
        .catch(err => dispatch(handleErrors(err)));
};
//Get Categories
export const getCategories = () => dispatch => {
    dispatch(setLoading(true));
    api.callPost('api/userdata/getAllUserCategories', {})
        .then(data => {
            dispatch(setLoading(false));
            dispatch({
                type: GET_CATEGORIES_SUCCESS,
                payload: data.results.categories
            });
            localStorage.setItem('userCategories', JSON.stringify(data.results.categories));
        })
        .catch(err => dispatch(handleErrors(err)));
};

//Get AccountTypes
export const getAccountTypes = () => dispatch => {
    api.callGet('api/admin/getAccountTypes')
        .then(data => {
            dispatch({
                type: GET_ACCOUNT_TYPE_SUCCESS,
                payload: data.actTypes || []
            });
            localStorage.setItem('accountTypes', JSON.stringify(data.actTypes || []));
        })
        .catch(err => dispatch(handleErrors(err)));
};

//load Categories
export const getServiceProviders = () => dispatch => {
    dispatch(setLoading(true));
    const userSettings = JSON.parse(localStorage.getItem('profileDetails')) || {};
    api.callPost('api/admin/getServiceProviderSync', {currency: userSettings.currency})
        .then(data => {
            dispatch(setLoading(false));
            dispatch({
                type: GET_SERVICE_PROVIDERS_SUCCESS,
                payload: data.results.serviceProviders
            });
            localStorage.setItem('serviceProviders', JSON.stringify(data.results.serviceProviders));
        })
        .catch(err => dispatch(handleErrors(err)));
};

export const getSingleServiceProvider = (id,callback) => dispatch => {
        api.callGet(`api/admin/serviceProvider/${id}`).then(data => {
            if(data.serviceProvider){
                let serviceProvider=JSON.parse(localStorage.getItem('serviceProviders'))
                serviceProvider.push(data.serviceProvider)
                localStorage.setItem('serviceProviders', JSON.stringify(serviceProvider));
                if(typeof callback === 'function') {
                    callback(true);
                }
            }
            else{
                if(typeof callback === 'function') {
                    callback(false);
                } 
            }
        }).catch(err=>{
            if(typeof callback === 'function') {
                callback(false);
            }
        })
};

//Get TimeZones
export const getTimeZones = () => dispatch => {
    dispatch(setLoading(true));
    api.callGet('api/admin/timezones')
        .then(data => {
            dispatch(setLoading(false));
            dispatch({
                type: GET_TIME_ZONES_SUCCESS,
                payload: data.timezones
            });
        })
        .catch(err => dispatch(handleErrors(err)));
};

//Get TimeZones
export const getAdminLanguages = () => dispatch => {
    dispatch(setLoading(true));
    api.callGet('api/admin/languages')
        .then(data => {
            dispatch(setLoading(false));
            dispatch({
                type: GET_LANGUAGES_SUCCESS,
                payload: data.result
            });
        })
        .catch(err => dispatch(handleErrors(err)));
};

export const updateNotificationStatus = ({key, value}) => dispatch => {
    let notificationDetails = localStorage.getItem('notificationDetails') || '{}';
    notificationDetails = JSON.parse(notificationDetails);
    notificationDetails[key] = value;
    localStorage.setItem('notificationDetails', JSON.stringify(notificationDetails));
    dispatch({
        type: NOTIFICATION_DETAILS_UPDATED,
        payload: notificationDetails
    });
};

export const getNoticeMessage = (cb) => dispatch => {
    api.callGet(`api/admin/notice/web`).then(res=>{
        if(cb !== 'undefined') {
            cb(res.results);
        }
    }).catch(err => dispatch(handleErrors(err)));
};

export const getIncomeExpenseImage = (imgId, cb, userId = false) => {
    if(!userId) {
        const userDetail = JSON.parse(localStorage.getItem('profileDetails'));
        userId = userDetail.userId;
    }
    api.umediaGet(`media/${userId}/${imgId}`).then(res=>{
        cb(res);
    }).catch(err=>{
        cb(err, false);
    })
};
//action creator for rejecting the invite request for the multiuser feature
//calls two APIs one for the groupcreator(inviter) data by public/group.
//and public/rejectinvite for rejecting the actual invite from the invitee side
// token is received in the url
export const rejectMultiUserInvitation = (token, callback) => dispatch => {
    let data = {};
    // token=kdjfkerekke033m3k43
    // api.callGet(`api/public/group/1589521262376DM5Hx49r6w`).then(res=>{
    api.callGet(`api/public/group/${token}`).then(res=>{
        data['groupData']=res.result;
        return api.callPost('api/public/group/rejectInvite', {token: token})
    })
        .then(res => {
            data['inviteData']=res.data;
            callback(data);
        })
        .catch(err => dispatch(handleErrors(err)));
};
//action creator for rejecting the invite request for the multiuser feature
//calls two APIs one for the groupcreator(inviter) data by public/group.
//and public/rejectinvite for rejecting the actual invite from the invitee side
// token is received in the url
export const acceptMultiUserInvitation = (token, callback) => dispatch => {
    let data = {};
    // token='1589521262376DM5Hx49r6w'
    api.callGet(`api/public/group/${token}`).then(res=>{
        data['groupData']=res.result;
        return api.callPost('api/public/group/getInvitedUserDetail', {token: token})
    }).then(response => {
        data['inviteData']=response.results;
    callback(data);
    }).catch(err => {dispatch(handleMultiUserErrors(err, data, 'getInvitedUserDetail'))});
};


export const acceptGroupInvitation = (form={}, callback) => dispatch => {
    // token='1589521262376DM5Hx49r6w'
    let data={};
    api.callPost('api/public/group/acceptInvite', form).then(res => {
        callback(res);
    }).catch(err => {dispatch(handleMultiUserErrors(err, data, 'acceptGroupInvitation'))});
};

export const handleMultiUserErrors = (data = null,additionalData , from) => dispatch => {
    const type='GET_ERRORS';
    const dataObj = data && data.data ? data.data : data;
    if(!!dataObj) {
        if (dataObj.code === 401) {
            dispatch(logoutUser());
        }
        dispatch({
            type: type,
            payload: {
                message: dataObj.message,
                code: dataObj.code,
                data: additionalData,
                type: from
            }
        });
    } else {
        dispatch({
            type: type,
            payload: {
                message: 'Unknown Error Occured!!',
                code: 404
            }
        });
    }
    dispatch(setLoading(false));
};

export const getGroupUsersDetails = ()=> dispatch =>{
    let details = {};
    api.callGet(`api/group/detail`).then(res=>{
        localStorage.setItem('familyShare', 'n')
        api.callPost('api/group/accounts').then(resp=>{
            dispatch({
                type: GET_GROUP_ACCOUNT_LIST_SUCCESS,
                payload: resp.results.accounts || []
            })
        }).catch(err=>console.log({err}));
        
        res.groups.forEach(e=>{
            if (e.firstName) {
                details.ownerDetail = e.firstName;
                if (e   .lastName) {
                    details.ownerDetail += ' ' + e.lastName;
                }
            }
            else {
                details.ownerDetail = e.email;
            }
            details.ownerEmail = e.email;
            if(e.userId) localStorage.setItem(e.userId,JSON.stringify(details));
            if(e.userId && e.profilePic) {
                dispatch(getGroupUsersImageBinary(e.userId,e.profilePic));
            }
            details={}
        })
    }).catch(err => {dispatch(handleErrors(err))});
};

export const getRefreshedGroupUsersDetails = (dispatchFn) =>{
    let details = {};
    api.callGet(`api/group/detail`).then(res=>{
        localStorage.setItem('familyShare', 'n')
        api.callPost('api/group/accounts').then(resp=>{
            dispatchFn({
                type: GET_GROUP_ACCOUNT_LIST_SUCCESS,
                payload: resp.results.accounts || []
            })
        }).catch(err=>console.log({err}));
        res.groups.forEach(e=>{
            if (e.firstName) {
                details.ownerDetail = e.firstName;
                if (e   .lastName) {
                    details.ownerDetail += ' ' + e.lastName;
                }
            }
            else {
                details.ownerDetail = e.email;
            }
            details.ownerEmail = e.email;
            if(e.userId) localStorage.setItem(e.userId,JSON.stringify(details));
            if(e.userId && e.profilePic) {
                dispatchFn(getGroupUsersImageBinary(e.userId,e.profilePic));
            }
            details={}
        })
    }).catch(err => {dispatchFn(handleErrors(err))});
};

export const getUserImageBinary = (userId,profilePic) =>{
let data = {};
    api.umediaGet(`media/${userId}/${profilePic}`).then(res=>{
    // api.umediaGet(`media/5e5b59e145e2611d9481325e/1589709672955_sign.png`).then(res=>{
        data = JSON.parse(localStorage.getItem(userId));
        data.profilePic = res;
        localStorage.setItem(userId,JSON.stringify(data));
    }).catch(err=>{
        console.log(err)
    })
};
export const getGroupUsersImageBinary = (userId,profilePic) => dispatch =>{
let data = {};
    api.umediaGet(`media/${userId}/${profilePic}`).then(res=>{
    // api.umediaGet(`media/5e5b59e145e2611d9481325e/1589709672955_sign.png`).then(res=>{
        data[userId]= res;
        dispatch({
            type: GROUP_USER_IMAGE_SUCCESS,
            payload: data
        })
    }).catch(err=>{
        console.log(err)
    })
};