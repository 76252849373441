
import SignUp from "../SignUp";
import SignIn from "../SignIn";
import OtpLogin from "../OtpLogin";
import LoadProfile from "../LoadProfile";
import VerifyEmail from "../VerifySignup";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";
import passwordUpdateSuccess from "../passwordUpdateSuccess";
import ComingSoon from "../common/ComingSoon";
import ActivationSuccess from "../ActivationSuccess";
import ResetSuccess from "../ResetSuccess";
import Dashboard from "../Dashboard";
import Expenses from "../Expenses";
import Settings from "../Settings";
import Bills from "../Bills";
import Income from "../Income";
import Budget from "../Budget";
import Report from "../Report";
import ChangePassword from "../ChangePassword";
import Success from "../Success";
import Template from "../Template";
import PageNotFound from "../PageNotFound";
import InvitationRejected from "../InvitationRejected";
import InvitationAccepted from "../InvitationAccepted";
import Account from "../Account";
import UnderManintenance from "../common/UnderManintenance";
import DeleteLogin from "../DeleteLogin";
import DeleteUserProfile from "../DeleteUserProfile";
import ImportData from "../ImportData";
import GoogleLogin from "../GoogleLogin";
// import ConnectToPlaid from "../ConnectToPlaid";

export default [
	{path: '/signup', component: OtpLogin, },
	{path: '/signin', component: OtpLogin, },
	{path: '/deleteProfile', component: DeleteUserProfile},
	{path: '/google-login', component: GoogleLogin},
	//{path: '/deleteUser', component: DeleteUserProfile},
	{path: '/forgot-password', component: ForgotPassword, },
	{path: '/account', component: ChangePassword, isPrivate: true},
	{path: '/success', component: Success, isPrivate: true},
	{path: '/', component: OtpLogin, },
	{path: '/verify-signup', component: VerifyEmail, },
	{path: '/activate', component: ActivationSuccess, },
	{path: '/dashboard', component: Dashboard, isPrivate: true},
	{path: '/bills', component: Bills, isPrivate: true, isProFeature: true},
	{path: '/loadingprofile', component: LoadProfile, isPrivate: true},
	{path: '/expenses', component: Expenses, isPrivate: true, isProFeature: true},
	{path: '/income', component: Income, isPrivate: true, isProFeature: true },
	{path: '/budget', component: Budget, isPrivate: true, isProFeature: true },
	{path: '/settings', component: Settings, isPrivate: true },
	{path: '/about', component: ComingSoon,isPrivate: true },
	{path: '/ads-free', component: ComingSoon, },
	{path: '/reports', component: Report, isPrivate: true, isProFeature: true },
	{path: '/resetpassword/:pin', component: ResetPassword, },
	{path: '/password-updated', component: passwordUpdateSuccess, },
	{path: '/coming-soon', component: ComingSoon, },
	{path: '/reset-success', component: ResetSuccess, },
	{path: '/feedback/:templateId/:feedbackId', component: Template},
	{path: '/page_not_found', component: PageNotFound},
	{path: '/inviteReject', component: InvitationRejected},
	{path: '/inviteAccept', component: InvitationAccepted},
	{path: '/account-detail', component: Account, isPrivate: true, isProFeature: true},
	{path: '/maintenance', component: UnderManintenance, isPrivate: true },
	{path: '/import-data', component: ImportData, isPrivate: true},
	// {path: '/plaid', component: ConnectToPlaid, isPrivate: true},
];