import React, { useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { history } from '../_helpers';
import { loginWithGoogle } from '../actions/authActions';
import { connect } from 'react-redux';
// import setAuthToken from '../utils/setAuthToken';
// import {
//   NOTIFICATION_DETAILS_UPDATED
// } from '../actions/types'
// import { setCurrentUser } from '../actions/authActions';

const GoogleLogin = ({ loginWithGoogle }) => {
  useEffect(() => {
    // Parse query parameters using native URLSearchParams
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token'); // Get the 'token' parameter

    if (token) {
      // Save the token to localStorage or sessionStorage
      loginWithGoogle(token);
      //Redirect to another page using React Router's history.push
      history.replace("/loadingprofile"); // Change to your desired route
    } else {
      console.error("Token not found in query parameters");
    }
  }, []);

  return (
    <div>
      <p>Loading...</p>
    </div>
  );
};

export default connect(null, { loginWithGoogle })(GoogleLogin);